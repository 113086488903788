<template>
  <div class="bookmark">
    <div>
      <v-row class="justify-space-between">
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div class="d-flex justify-md-start justify-start">
            <v-text-field
              v-model="search"
              label="Cari Topik/Post"
              dense
              outlined
              @change="searchHandler($event)"
            ></v-text-field>
          </div>
        </v-col>

        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div class="d-flex justify-md-end justify-space-between">
            <div class="mt-2">
              <h4 class="font-weight-regular mr-3">
                Filter :
              </h4>
            </div>
            <div class="d-flex align-center">
              <v-autocomplete
                v-model="filter"
                :items="filters"
                label="Filter"
                return-object
                item-text="title"
                item-value="filter"
                dense
                outlined
                open-on-clear
                @change="filterHandler($event)"
              >
              </v-autocomplete>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <div class="d-flex justify-between align-center mb-5 scroll-menu">
        <template>
          <div class="demo-space-x">
            <v-chip
              :outlined="categoryActive == '' ? false : true"
              :color="categoryActive == '' ? 'primary' : ''"
              @click="filterByChip('')"
            >
              Semua
            </v-chip>
          </div>
        </template>
        <template>
          <div class="demo-space-x">
            <v-chip
              v-for="category in categories"
              :key="category.uuid"
              :outlined="categoryActive == category.uuid ? false : true"
              :color="categoryActive == category.uuid ? 'primary' : ''"
              @click.prevent="filterByChip(category.uuid)"
            >
              {{ category.title }}
            </v-chip>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!isLoadingCard">
      <v-row
        class="mb-5"
      >
        <v-col
          v-for="forum in filtered"
          :key="forum.uuid"
          md="4"
        >
          <v-card>
            <v-card
              color="#7D2B8B"
              height="150"
            >
              <div
                v-if="forum.users.uuid === user_uuid"
                class="pa-2 position-absolute"
                style="left: 0; z-index: 1"
              >
                <v-spacer></v-spacer>
                <template>
                  <div class="demo-space-x">
                    <v-chip
                      color="primary"
                      class="forum-chip"
                      x-small
                    >
                      Forum Saya
                    </v-chip>
                  </div>
                </template>
              </div>
              <v-card-title
                v-if="forum.users.uuid === user_uuid"
                class="pa-2 position-absolute"
                style="right: 0"
              >
                <v-spacer></v-spacer>
                <!-- <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a @click.prevent="editPost(forum.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small
                              class="ml-2"
                            >Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click.prevent="confirmPostDestroy(forum.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small
                              class="ml-2"
                            >Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu> -->
              </v-card-title>
              <div class="d-flex justify-center">
                <div>
                  <v-img
                    height="150"
                    width="220"
                    :src="forum.image"
                  >
                  </v-img>
                </div>
              </div>
            </v-card>

            <router-link
              :to="{ name: 'forum-detail',params: {uuid : forum.uuid}}"
              class="text-decoration-none text"
            >
              <v-card-text class="pa-4">
                <div>
                  <div class="d-flex justify-md-space-between mb-4 text-forum">
                    <span>{{ getFormatDate(forum.created_at) }}</span>
                    <span>{{ forum.topic.title }}</span>
                  </div>
                  <h3 class="mb-4">
                    {{ forum.title }}
                  </h3>
                  <div class="d-flex align-center">
                    <v-avatar size="30">
                      <v-img src="@/assets/images/avatars/1.png"></v-img>
                    </v-avatar>
                    <span class="ms-2 text-forum">{{ forum.users.name }}</span>
                  </div>
                </div>
              </v-card-text>
            </router-link>
            <v-divider>
            </v-divider>
            <v-card-text class="pa-4">
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-start">
                  <div class="d-flex align-center me-4">
                    <img
                      v-if="postLikes.includes(forum.uuid)"
                      src="@/assets/icons/color/like-thick.svg"
                      width="22"
                      class="pointer"
                      @click="addLike(forum.uuid)"
                    >
                    <img
                      v-else
                      src="@/assets/icons/like.svg"
                      width="22"
                      alt=""
                      class="pointer"
                      @click="addLike(forum.uuid)"
                    >
                    <span class="ms-2">{{ forum.like_count }} Suka</span>
                  </div>
                  <div class="d-flex align-center">
                    <img
                      src="@/assets/icons/messages.svg"
                      width="22"
                      alt=""
                    >
                    <span class="ms-2">{{ forum.comment_count }} Komentar</span>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/icons/archive-tick.svg"
                    width="22"
                    alt=""
                    class="cursor-pointer"
                    @click="addBookmark(forum.uuid)"
                  >

                  <!-- <img
                    v-else
                    src="@/assets/icons/archive-add.svg"
                    width="22"
                    alt=""
                    class="cursor-pointer"
                    @click="addBookmark(forum.uuid)"
                  > -->
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row
        class="mb-5"
      >
        <v-col
          v-for="i,index in 9"
          :key="index"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          cols="12"
        >
          <v-skeleton-loader
            height="150"
            type="card-avatar,article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="isEmpty"
      class="d-block text-center"
    >
      <div

        class="d-block"
      >
        <div class="d-flex justify-center">
          <div
            class="px-4 py-8 text-center"
          >
            <v-img
              src="@/assets/images/vector/empty-forum.svg"
              class="mx-8 my-auto"
            ></v-img>
            <p class="mt-3 mb-0">
              Belum ada bookmark
            </p>
          </div>
        </div>
      </div>
    </div>

    <div

      class="mt-6"
    >
      <v-pagination
        v-if="totalPages"
        v-model="page"
        :length="totalPages"
        total-visible="6"
        @change="paginationHandler"
      ></v-pagination>
    </div>

    <ModalDialog
      :visible="modalDialog"
      :width="width"
      header="Edit Bookmark"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      text-button="Update"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
      @handler-button="updatePost"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="postEdit.topic"
            :items="categories"
            label="Topik"
            return-object
            item-text="title"
            item-value="uuid"
            dense
            outlined
            open-on-clear
          ></v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="postEdit.title"
            label="Judul Post"
            dense
            outlined
          >
          </v-text-field>
        </div>
        <div>
          <FileInput
            v-model="initialFile"
            label="Gambar"
            :value="postEdit.image"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
          <small class="d-block ms-8 mb-4">File .jpg/.png, max. 3mb</small>
        </div>
        <div>
          <v-textarea
            v-model="postEdit.content"
            label="Deskripsi Post"
            outlined
            dense
          >
          </v-textarea>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmPostDialog"
      header="Hapus Post"
      :is-loading-button="isLoadingButton"
      @destroy-button="deletePost"
      @close-button="confirmPostDialog = false"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { mdiArrowLeft, mdiChevronDown, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'Forum',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiArrowLeft,
        mdiChevronDown,
      },
      filter: '',
      filters: [
        {
          id: 1,
          title: 'Terbaru',
          filter: 'newest',
        },
        {
          id: 2,
          title: 'Popular',
          filter: 'popular',
        },
        {
          id: 3,
          title: 'Suka',
          filter: 'like',
        },
      ],
      topic: null,
      isLoadingButton: false,
      confirmDestroy: false,
      postEdit: {
        title: '',
        content: '',
        topic: {},
      },
      totalPages: 0,
      totalItems: 0,
      page: 1,
      width: 600,
      modalDialog: false,
      confirmPostDialog: false,
      isEmpty: false,
      filtered: [],
      categoryActive: '',
      hasPrimary: false,
      school: {},
      user_uuid: null,
      school_uuid: null,
      isMyForum: false,
      categories: [],
      forums: [],
      like: {
        users_uuid: null,
        forum_uuid: null,
      },
      bookmark: {
        users_uuid: null,
        forum_uuid: null,
      },
      bookmarks: [],
      forumUuid: null,
      likes: [],
      alreadyLike: false,
      postLikes: [],
      isLoadingCard: true,
      postBookmark: [],
      search: '',
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.postEdit.image === 'string') {
          return new File([this.postEdit.image], this.postEdit.image, { type: 'text/plain' })
        }

        return this.postEdit.image
      },
      set() {
        return this.postEdit.image
      },
    },
  },
  watch: {
    postEdit: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content']
        Object.entries(this.postEdit).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    search: {
      handler() {
        this.isLoadingCard = true
        this.getForums()
      },
    },
    page: {
      handler() {
        this.isLoadingCard = true
        this.getForums()
      },
    },
    filter: {
      handler() {
        this.isLoadingCard = true
        this.getForums()
      },
    },
  },

  async mounted() {
    this.getSchoolUuid()
    await this.getCategories()
    await this.getForums()

    await this.getLike()
    // this.getBookmark()
    this.isLoadingCard = false
  },
  methods: {
    searchHandler(data) {
      this.search = data
    },
    filterHandler(data) {
      this.filter = data.filter
    },
    async getForums() {
      this.isLoadingCard = true

      await this.$services.ApiServices.listParams('bookmark', this.user_uuid, {
        per_page: 9,
        page: this.page,
        search: this.search,
        filters: this.filter,
      }).then(
        ({ data }) => {
          this.forums = data.data
          this.isEmpty = false
          if (this.forums.length < 1) {
            this.isEmpty = true
          }
          this.filtered = data.data
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
    async getCategories() {
      await this.$services.ApiServices.list('forumtopic', { school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async getLike() {
      await this.$services.ApiServices.get('likeforum', this.user_uuid).then(
        ({ data }) => {
          this.likes = data.data
          this.likes.forEach(item => this.postLikes.push(item.uuid))
        },
        err => console.error(err),
      )
    },
    // async getBookmark() {
    //   await this.$services.ApiServices.get('bookmark', this.user_uuid).then(
    //     ({ data }) => {
    //       this.bookmarks = data.data
    //       this.bookmarks.forEach(item => this.postBookmark.push(item.uuid))
    //     },
    //     err => console.error(err),
    //   )
    // },
    async addLike(uuid) {
      this.like.users_uuid = this.user_uuid
      this.like.forum_uuid = uuid
      if (!this.postLikes.includes(uuid)) {
        this.postLikes.push(uuid)
      } else {
        const index = this.postLikes.indexOf(uuid)

        this.postLikes.splice(index, 1)
      }
      await this.$services.ApiServices.add('likeforum', this.like).then(
        ({ data }) => {
          // this.postLikes = this.postLikes.filter(item => item !== uuid)
        },
        err => console.error(err),
      )
      // await this.getLike()
      await this.getForums()
    },
    async addBookmark(uuid) {
      this.bookmark.users_uuid = this.user_uuid
      this.bookmark.forum_uuid = uuid
      if (!this.postBookmark.includes(uuid)) {
        this.postBookmark.push(uuid)
      } else {
        const index = this.postBookmark.indexOf(uuid)

        this.postBookmark.splice(index, 1)
      }
      await this.$services.ApiServices.add('bookmark', this.bookmark).then(
        ({ data }) => {
          // this.postBookmark = this.postBookmark.filter(item => item !== uuid)
        },
        err => console.error(err),
      )
      await this.getForums()
    },
    async editPost(uuid) {
      this.forumUuid = uuid
      await this.$services.ApiServices.get('forum', this.forumUuid).then(({ data }) => {
        this.postEdit = data.data
      })
      this.modalDialog = true
    },
    async updatePost() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('topic_uuid', this.postEdit.topic.uuid)
      this.formData.append('user_id', this.user_uuid)
      this.formData.append('content', this.postEdit.content)
      this.formData.append('title', this.postEdit.title)
      this.formData.append('image', this.postEdit.image)
      if (typeof this.postEdit.image === 'string') {
        this.formData.delete('image')
      }
      await this.$services.ApiServices.update('forum', this.formData, this.forumUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update Post to post successfully',
            color: 'success',
          })
          this.isLoadingButton = false
          this.forumUuid = null
        },
        err => console.error(err),
      )
      this.modalDialog = false
      await this.getForums()
      await this.getLike()
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    getFile(data) {
      this.postEdit.image = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    confirmPostDestroy(uuid) {
      this.confirmPostDialog = true
      this.forumUuid = uuid
    },
    async deletePost(uuid) {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('forum', this.forumUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Delete post successfully',
            color: 'error',
          })
          this.isLoadingButton = false
          this.forumUuid = null
          this.confirmPostDialog = false
        },
        err => console.error(err),
      )
      await this.getForums()
      await this.getLike()
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${time}, ${year}/${month.slice(-2)}/${day.slice(-2)}`
    },
    filterByChip(uuid) {
      this.isLoadingCard = true
      this.categoryActive = uuid
      this.filtered = this.forums.filter(post => post.topic.uuid === uuid)
      if (uuid === '') {
        this.filtered = this.forums
      }
      this.isLoadingCard = false

      return this.filtered
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.scroll-menu {
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-menu:hover {
  overflow-x: auto;
}

.demo-space-x::v-deep .v-chip {
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 24px !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip::v-deep {
  background-color: #ffffff !important;
}

.demo-space-x::v-deep .forum-chip {
  padding: 2px 10px !important;
}

.btn-delete {
  color: #e30000 !important;
}

.text-forum {
  color: rgba(94, 86, 105, 0.68) !important;
}
.pointer {
  cursor: pointer;
}
.text-suspend {
  color: #e30000 !important;
}

.replyToggle {
  transition: transform 0.3s ease-in-out !important;
}

.replyToggle.rotate {
  transform: rotate(180deg);
}
</style>
